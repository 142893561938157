
.title {
	color: #2f925b;
	font-size: 1.75em;
	font-family: "Droid Sans", Helvetica, Arial, sans-serif;
	margin-top: 25px;
	text-transform: uppercase;
	font-weight: bold;
	text-align: center;
}

a.nav-item {
    text-decoration: bold, underline;
    color: #2f925b;
    font-size: .9em;
    margin-top: .5em;
    margin-bottom: .5em;
}

a.nav-item:hover {
    background-color: rgba(0,130,0,0.25);
}

.nav-border {
    border-color: green;
    border-style: solid;
    border-width: 1px;
}

.view-mode-heading
{
    color: #2f925b;
    text-transform: uppercase;
    font-size: 1.9em;
}

.user-name {
    text-transform: uppercase;
    font-size: 1em;
}

.user-email {
    margin-left: .5em;
    font-size: .75em;
}

.callout.context-item {
    background-color: #DDDDDD;
}

.callout.panel {
    background-color: inherit;
    padding-bottom: 0;
    padding-top: 0;
    border-bottom: none;
    border-style: none;
}

.callout.list-panel {
    background-color: inherit;
    padding-bottom: 0;
    padding-top: 0;
    border-bottom: none;
    border-style: none;
}

.callout.noborder {
    text-transform: uppercase;
    font-size: .9em;
    background-color: inherit;
    border-style: none;
    border-width: 0;
    padding-bottom: 0;
    padding-top: 0;
    border-bottom: none;
}

header
{
    padding-top: 1.2rem;
    padding-bottom: 1rem;
    position: relative;
    background: rgba(0,0,0,0.05);
    border-bottom: 1px solid #bbbbbb;
    text-shadow: 1px 1px 0 rgba(255,255,255,0.9);
}

.logo-container
{
    padding-top: 1rem;
    margin-bottom: 1rem;
}

.gb-icon
{
    color: #2f925b;
    font-size: 1em;
    margin-right: .5em;
    verticle-align: middle;
}

.gr-icon
{
    color: #0B7B6F;
    font-size: 1em;
    margin-right: .5em;
    verticle-align: middle;
}

div.row.invite-row
{
    padding-top: .75em;
    padding-bottom: .75em;
    border-bottom-style: solid;
    position: relative;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: green;
}

.row-link {
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left: 0;

    z-index: 1;

    /* fixes overlap error in IE7/8,
       make sure you have an empty gif */
    background-image: url('empty.gif');
}

.vertical-bar {
	border-left: 2px solid green;
}

div.columns.list-column {
    padding-top: 10px;
    padding-bottom: 10px;
}

footer div.row {
    max-width: 62rem;
}

/*- Footer */

footer
{
    background: url("/media/footer_bg.jpg") !important;
    border-top: 0.13rem solid rgba(255, 255, 255, 0.1);
}

footer div.copyright {
    background: rgba(0, 0, 0, 0.2);
    height: 2.65rem;
    line-height: 2.65rem;
    color: #c2c2c2;
    font-size: 0.7rem;p
	text-shadow: 1px 1px 0 rgba(80, 80, 80, 0.3);
    vertical-align: middle;
}

footer div.links {
    background: rgba(0, 0, 0, 0.2);
    height: 3.45rem;
    color: #c2c2c2;
    font-size: 0.7rem;
    text-shadow: 1px 1px 0 rgba(80, 80, 80, 0.3);
    vertical-align: middle;
    padding-top: 10px;
}

footer div.links a:link,
footer div.links a:visited {
    color: #c2c2c2;
}


footer div.links a:hover {
    color: #ffffff;
    text-decoration: underline;
}

footer div.bottom-color-bar {
    border-top: 0.1rem solid rgba(220, 220, 220, 0.1);
}


/* Loader */

.loader-center {
	position: absolute;
	left: 50%;
	margin-left: -60px;
	margin-top: 60px;
}

.loader {
    border: 14px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #2f925b; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

