.button.loading
{
	background-size: 85px 85px;
	background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(0,0,0,.5) 5px, rgba(0,0,0,.5) 10px);
	animation: hundredPxRight 5.0s linear infinite;
	cursor: wait;
	
}

.button.loading.secondary
{
	background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(255,255,255,.5) 5px, rgba(255,255,255,.5) 10px);
	
}

@keyframes hundredPxRight
{
	from { background-position: 0 0; }
	to   { background-position: 100px 0; }
	
}